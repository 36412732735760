const menuItems = {
    items: [
        {
            id: 'navigation',
            title: 'Navigation',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/app/dashboard/default',
                    icon: 'feather icon-home'
                },
                {
                    id: 'projects',
                    title: 'Projects',
                    type: 'item',
                    url: '/projects',
                    icon: 'feather icon-box'
                },
                {
                    id: 'peoples',
                    title: 'People',
                    type: 'item',
                    url: '/peoples',
                    icon: 'feather icon-users'
                },
                {
                    id: 'users',
                    title: 'Users',
                    type: 'item',
                    url: '/users',
                    icon: 'feather icon-user-plus'
                }
            ]
        }
    ]
};

export default menuItems;
